<template>
  <input
    v-if="!isTextArea"
    :id="props.id"
    :value="props.modelValue"
    :type="props.type"
    class="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none focus:ring-green-main sm:text-sm disabled:bg-gray-300"
    :placeholder="props.placeholder"
    :disabled="disabled"
    @input="emits('update:modelValue', $event?.target?.value)"
    @focus="emits('focus')"
    @blur="emits('blur')"
    @keyup.enter="emits('enter')"
  />
  <textarea
    v-else
    :id="props.id"
    :value="props.modelValue"
    :placeholder="props.placeholder"
    :disabled="disabled"
    class="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none focus:ring-green-main sm:text-sm disabled:bg-gray-300"
    @input="emits('update:modelValue', $event?.target?.value)"
    @focus="emits('focus')"
    @blur="emits('blur')"
    @keyup.enter="emits('enter')"
  />
</template>

<script lang="ts" setup>
const props = defineProps({
  id: {
    type: String,
    default: () => (Math.random() + 1).toString(36).substring(7),
  },
  placeholder: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
    validator: (value: string) => {
      const inputTypes = ['text', 'number', 'date', 'email', 'tel', 'password', 'time']
      return inputTypes.includes(value)
    },
  },
  isTextArea: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {},
})

const emits = defineEmits<{
  (e: 'update:modelValue', v: any): void
  (e: 'focus'): void
  (e: 'blur'): void
  (e: 'enter'): void
}>()
</script>

<style scoped></style>
